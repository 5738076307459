import styled, { createGlobalStyle } from 'styled-components'
import { Media, typography } from '@renderbus/common/theme'
import RoboBlackItalic from '../font/Robo-BlackItalic.ttf'
import RobotoBold from '../font/Roboto-Bold.ttf'
import RobotoRegular from '../font/Roboto-Regular.ttf'
import CheckedIcon from '../images/render-contest/check.png'
import WarningIcon from '../images/render-contest/warning@2x.png'

export const RCStyle = createGlobalStyle`
  @font-face {
    font-family: 'Robo-BlackItalic';
    src: url(${RoboBlackItalic});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Bold';
    src: url(${RobotoBold});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Regular';
    src: url(${RobotoRegular});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  html {
    background-color: #0B1738;
  }
  .hide {
    display: none !important;
  }
  .hideInMobile {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
  .hideInPC {
    ${Media.greaterThan(Media.small)} {
      display: none !important;
    }
  }
`
export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
  }
`
export const PageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 73px;
  width: 1200px;
  height: 63px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  border-top: 1px solid #999999;
  color: #b0b6bc;
  img {
    margin: 10px 11px 0 5px;
    transform: translate(0, -50%);
  }
  a:hover {
    color: #5195f5;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const PageFooterMobile = styled.div`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    margin: 20px 16px 40px;
    img {
      width: 91px;
      height: 91px;
    }
    & > div {
      margin-top: 10px;
    }
  }
`
export const IndexSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`
export const TitleSection = styled(IndexSection)`
  align-items: center;
  color: #ffffff;
  h2 {
    margin: 0;
  }
  > img:first-child {
    width: 233px;
    height: 85px;
    & + h2 {
      position: absolute;
      line-height: 85px;
    }
  }
  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 8.13vw;
    > img:first-child {
      width: 31.06vw;
      height: 11.33vw;
      & + h2 {
        position: absolute;
        line-height: 11.33vw;
      }
    }
    h2 {
      font-size: 3.99vw;
      font-weight: bold;
      line-height: 36px;
    }
  }
`
export const RCFormSection = styled(TitleSection)`
  margin: 59px 0 0 0;
  color: #3d3d3d;
  margin-bottom: 100px;
  ${Media.lessThan(Media.small)} {
    margin: 35px 16px 0;
  }
`
export const RCSignFormContainer = styled.form`
  width: 1200px;
  font-size: 14px;
  background: linear-gradient(45deg, #ffffff 85%, #a8def4 99%);
  border-radius: 8px;
  padding: 30px 150px 85px;
  ${Media.lessThan(Media.small)} {
    padding: 32px 16px 0;
    width: 100%;
  }
`
export const QrContainer = styled.div`
  position: fixed;
  width: 187px;
  height: 320px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: linear-gradient(220deg, #daf4ff 25%, #7ed6fa 93%, #7ed6fa 100%),
    linear-gradient(45deg, #ffffff 66%, #a8def4 99%);
  box-shadow: 0px 4px 24px 0px rgba(24, 64, 112, 0.08);
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  color: #3d3d3d;
  font-size: ${typography.text};
  font-weight: 500;
  img {
    width: 155px;
    height: 155px;
  }
  p {
    line-height: 23px;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const RCSignFormTitle = styled.div`
  text-align: center;
  margin-bottom: 50px;
  font-size: ${typography.h3};
  font-weight: 700;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
    margin-bottom: 30px;
  }
`
export const RCSignForm = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const RCInputField = styled.div`
  width: 100%;
`
export const ErrorWrapper = styled.div`
  color: #f14638;
  line-height: 17px;
  font-size: 12px;
  margin-top: 8px;
  span {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #fe4840;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 14px;
    margin-right: 4px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 4px;
    font-size: 10px;
    span {
      width: 13px;
      height: 13px;
      line-height: 13px;
      margin-right: 5px;
    }
  }
`
export const RCInput = styled.input`
  width: 300px;
  height: 40px;
  background: #f4f4f4;
  border: 1px solid #dddddd;
  border-radius: 4px;
  outline-style: none;
  padding: 10px 20px;
  font-size: 14px;
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:active,
  &:focus {
    background-color: #ffffff;
  }

  &.error {
    border: 1px solid red;
    background: #f4f4f4;
  }
  &.other-input {
    padding: 0;
    width: 120px;
    background: #ffffff;
    height: 24px;
  }
  ${Media.lessThan(Media.small)} {
    height: 30px;
    width: 100%;
    font-size: 12px;
    &.other-input {
      margin: 0;
      height: 18px;
      width: 80px;
    }
  }
`
export const RCRegisterLabel = styled.span`
  font-size: ${typography.textSmall};
  color: #666666;
  margin-left: 20px;
  white-space: nowrap;
  a {
    color: #5195f5;
  }
  display: ${p => (!p.isMobile ? 'inline-block' : 'none')};
  ${Media.lessThan(Media.small)} {
    display: ${p => (p.isMobile ? 'block' : 'none')};
    font-size: 10px;
    margin: 0;
  }
`
export const RCRenderbusSupport = styled.div`
  font-size: 12px;
  display: ${p => (!p.isMobile ? 'inline-block' : 'none')};
  ${Media.lessThan(Media.small)} {
    display: ${p => (p.isMobile ? 'inline-block' : 'none')};
    font-size: 9px;
  }
`
export const BottomLine = styled.div`
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 20px auto 30px;
  ${Media.lessThan(Media.small)} {
    margin: 10px auto 20px;
  }
`
export const StudentCardContainer = styled.div`
  margin-left: 270px;
  position: relative;
  display: flex;
`
export const RCRadio = styled.input`
  cursor: pointer;
  opacity: 0;
  margin-bottom: 20px;
  + span {
    position: relative;
    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translate(0, -50%);
      width: 15px;
      height: 15px;
      border: 1px solid #666666;
      border-radius: 50%;
      background: transparent;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -24px;
      top: 50%;
      transform: translate(0, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }
  }
  &:checked {
    + span::before {
      border: 1px solid #539df6;
    }
    + span::after {
      background: #539df6;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-bottom: 0;
    + span {
      margin-left: 8px !important;
      font-size: 12px;
      &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        left: -23px;
        top: 50%;
        transform: translate(0, -50%);
        width: 15px;
        height: 15px;
        border: 1px solid #666666;
        border-radius: 50%;
        background: transparent;
      }
      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        left: -19px;
        top: 50%;
        transform: translate(0, -50%);
        width: 7px;
        height: 7px;
        border-radius: 50%;
      }
    }
  }
`
export const RCRadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${p => `${p.left}px`};
  width: 100%;
  // position: relative;
  div {
    width: 25%;
    position: relative;
    span {
      margin-left: 15px;
    }
    ${RCInput} {
      position: absolute;
      border: none;
      border-bottom: 1px solid #999999;
      border-radius: unset;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin: 0;
    width: 100%;
    margin-left: 4px;
    div {
      width: 50%;
      margin-bottom: 20px;
    }
  }
`
export const RCOption = styled.span`
  cursor: pointer;
  color: ${p => (p.isActive ? '#5195F5' : '#DDDDDD')};
  background: ${p => p.isActive && '#2F323E'};
  line-height: 30px;
  font-size: 14px;
  padding-left: 74px;
  &::before {
    content: 'o ';
  }
  &:hover {
    background: #2f323e;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 2.39vw;
    line-height: 3.99vw;
    padding-left: 9.86vw;
  }
`
export const RCOptionContainer = styled.div`
  display: ${p => (p.isActive ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: #252a3a;
  width: 100%;
  max-height: 280px;
  overflow: hidden;
  z-index: 5;
  .ps__rail-y {
    width: 10px;
    padding-left: 10px !important;
    background: transparent !important;
    .ps__thumb-y {
      width: 10px;
      padding-left: 10px !important;
      background-color: #666666;
    }
  }
  ${Media.lessThan(Media.small)} {
    max-height: 37.33vw;
  }
`

export const RCLabel = styled.div`
  width: 200px;
  text-align: right;
  padding-right: 12px;
  font-size: 16px;
  white-space: nowrap;
  ::before {
    display: inline-block;
    content: '';
    width: 6px;
    height: 6px;
    background: #f14638;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    flex: none;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    padding-right: 8px;
    width: 150px;
    font-size: 12px;
    &.long-question {
      white-space: unset;
      width: 100%;
      display: flex;
      align-items: baseline;
    }
  }
`
export const RCCheckbox = styled.div`
  cursor: pointer;
  width: 12px;
  height: 12px;
  border: ${p => !p.checked && '1px solid #c7c7c7'};
  border-radius: 2px;
  background: ${p => (p.checked ? `url(${CheckedIcon}) no-repeat` : ``)};
  background-size: 100%;
  flex: none;
  &.error {
    border-color: red;
  }
`
export const RCAgreements = styled.div`
  font-size: 14px;
  line-height: 20px;
  ${RCCheckbox} {
    position: relative;
    line-height: 20px;
    bottom: -3px;
  }
  div {
    display: flex;
    margin-bottom: 10px;
  }
  .check-text {
    margin-bottom: 16px;
  }
  span {
    line-height: 20px;
    font-weight: 400;
    color: #666666;
    margin-left: 8px;
  }
  a {
    text-decoration: underline;
  }
  ${Media.lessThan(Media.small)} {
    margin: 0;
    font-size: 10px;
    color: #666666;
    ${RCCheckbox} {
      position: relative;
      line-height: 3.86vw;
      bottom: -3px;
      width: 3.86vw;
      height: 3.86vw;
    }
    span {
      font-size: 10px;
    }
  }
`

export const RCButton = styled.button`
  margin-left: 50%;
  transform: translate(-50%);
  width: 176px;
  height: 47px;
  background: #d8d8d8;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  color: #3d3d3d;
  line-height: 24px;
  margin-top: 40px;
  ${Media.lessThan(Media.small)} {
    display: block;
    width: 176px;
    height: 47px;
    font-size: 3.19vw;
    line-height: 8vw;
    padding: unset;
    margin: 40px auto;
    transform: unset;
  }
`
export const RCFormItem = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  width: 50%;
  .account-form-item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: baseline;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`
export const RCOrgFormItem = styled(RCFormItem)`
  width: 100%;
  ${RCLabel} {
    width: 100%;
  }
  ${RCInput} {
    width: 608px;
  }
  ${Media.lessThan(Media.small)} {
    flex-wrap: wrap;
    ${RCLabel} {
      text-align: left;
      margin-bottom: 8px;
    }
    ${RCInput} {
      width: 100%;
    }
  }
`
export const RCSelectFormItem = styled(RCFormItem)`
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  ${RCLabel} {
    text-align: left;
    margin-bottom: 24px;
  }
  ${Media.lessThan(Media.small)} {
    justify-content: flex-start;
    ${RCLabel} {
      text-align: left;
      margin-bottom: 8px;
    }
  }
`
export const RCDialogContainer = styled.div`
  position: fixed;
  display: ${p => (p.isActive ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
  .content {
    display: flex;
    padding: 60px 60px 60px 40px;
    border-radius: 14px 14px 14px 14px;
    width: 605px;
    height: 295px;
    background: #ffffff;
    img {
      width: 180px;
      height: 180px;
      background: linear-gradient(
        229deg,
        rgba(218, 244, 255, 0.5) 0%,
        rgba(126, 214, 250, 0.5) 100%
      );
      padding: 19px;
      border-radius: 10px;
    }
    .text {
      margin-left: 26px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        margin: 0 0 21px 0;
        color: #333333;
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
      }
      span {
        color: #666666;
        font-size: 14px;
        line-height: 14px;
      }
      button {
        width: 176px;
        height: 47px;
        background: #d8d8d8 linear-gradient(229deg, #daf4ff 0%, #7ed6fa 100%);
        border-radius: 6px;
        font-size: 16px;
        font-weight: bold;
        color: #3d3d3d;
        border: none;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: max-content;
      justify-content: center;
      align-items: center;
      height: max-content;
      padding: 8vw 5.33vw;
      margin: 12.8vw;
      img {
        width: 37.33vw;
        height: 37.33vw;
        padding: 8px;
      }
      .text {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        p {
          margin: 12px 0 8px 0;
          font-size: 5vw;
          line-height: 5vw;
          font-weight: bold;
        }
        span {
          font-size: 3.73vw;
          line-height: 5.33vw;
        }
        button {
          margin-top: 6.67vw;
          width: 46.93vw;
          height: 10.4vw;
          font-size: 4.26vw;
          color: #3d3d3d;
          border: none;
        }
      }
    }
  }
`
export const ErrorText = styled.div`
  display: none;
  color: red;
  position: absolute;
  font-size: 12px;
  margin-top: 6px;
  align-items: center;
  height: 17px;
  &#info-from-error,
  &#machine-error,
  &#will-contact-error,
  &#care-about-error {
    bottom: -20px;
  }
  &#account-error {
    position: relative;
  }
  &::before {
    display: inline-block;
    width: 16px;
    height: 16px;
    content: '';
    background: url(${WarningIcon}) no-repeat;
    background-size: 100% 100%;
    margin-right: 4px;
  }
  &.error {
    display: flex;
  }
  ${Media.lessThan(Media.small)} {
    display: none !important;
  }
`
export const InputContent = styled.div`
  display: inline-block;
`
export const RCCheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  > div {
    display: flex;
    width: 25%;
    align-items: center;
    gap: 8px;
    line-height: 30px;
  }
  ${Media.lessThan(Media.small)} {
    > div {
      width: 50%;
    }
  }
`
export const RCFormCheckbox = styled.input`
  cursor: pointer;
  opacity: 0;
  margin-left: 10px;
  + span {
    position: relative;
    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
      border: 1px solid #666666;
      background: transparent;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
    }
  }
  &:checked {
    + span::before {
      border: 1px solid #539df6;
    }
    + span::after {
      background: url(${CheckedIcon}) no-repeat;
      background-size: 100% 100%;
    }
  }
`
