import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { Plupload } from '@renderbus/common/utils'
import QRImg from '../images/render-contest/qr@2x.png'

import { SEO } from '@renderbus/common/components'
import {
  BannerContainer,
  RCFormContainer,
  RCSubmitStyle,
  RCForm,
  FormTitle,
  FormSubTitle,
  FormItem,
  FormItemLabel,
  RCSubmitButton,
  FormInput,
  RCRadioContainer,
  WorkUploadInput,
  RCDialogContainer,
  BottomLine,
  RCCheckbox,
  ErrorText,
} from './rc-submit.atom'

import { QrContainer } from './rc-form.atom'

import BannerBg from '../images/render-contest/submit_banner_bg.png'
import PngIcon from '../images/render-contest/png_icon.png'
import PngWarningIcon from '../images/render-contest/png_warning_icon.png'
import Mp4Icon from '../images/render-contest/mp4_icon.png'
import Mp4WarningIcon from '../images/render-contest/mp4_warning_icon.png'
import CloseIcon from '../images/render-contest/close.png'
import CloseWarningIcon from '../images/render-contest/close_warning.png'
// import { isTest, baseLink } from '@renderbus/common/service'

function RCSubmit() {
  const initForm = {
    name: '',
    phone: '',
    workName: '',
    workDescription: '',
    software: '',
    renderer: '',
    hardware: '',
    cycle: '',
    sources: [],
    bilibili: '',
    zhanku: '',
    redBook: '',
    douyin: '',
  }
  const infoSourceOptions = [
    'Style3D',
    'Zeno',
    'Facegood',
    'PBRMAX',
    'Renderbus',
    '青椒云',
    '以上都未使用',
  ]
  const inputFields = new Map([
    ['name', '参赛姓名'],
    ['phone', '联系电话'],
    ['software', '制作软件'],
    ['renderer', '渲染引擎'],
    ['hardware', '硬件配置'],
    ['cycle', '制作周期'],
    ['workName', '作品名称'],
    ['workDescription', '作品描述'],
    ['sources', '软件资源'],
    ['bilibili', 'B站'],
  ])
  const [formValid, setFormValid] = useState(false)
  const [formData, setFormData] = useState({ ...initForm })
  const [uploadResult, setUploadResult] = useState(null)
  const [submitParams, setSubmitParams] = useState(null)
  const [uploader, setUploader] = useState(null)
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [submiting, setSubmiting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadCoverResult, setUploadCoverResult] = useState(null)
  const [uploaderCover, setUploaderCover] = useState(null)
  const [uploadingCover, setUploadingCover] = useState(false)
  const [coverName, setCoverName] = useState('')
  const [coverSize, setCoverSize] = useState(0)
  const [isCoverTypeError, setIsCoverTypeError] = useState(false)
  const [workMp4Name, setWorkMp4Name] = useState('')
  const [workMp4Size, setWorkMp4Size] = useState(0)
  const [isWorkTypeError, setIsWorkTypeError] = useState(false)

  function submitWorkInfo() {
    if (submiting) return
    setSubmiting(true)
    let param = { ...submitParams }
    param.sources = param.sources.toString()
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/rc-03/submit-work-info',
      data: param,
    })
      .then((response) => {
        if (response.data.code === 200) {
          setSubmitSuccess(true)
        } else {
          setSubmitSuccess(false)
        }
      })
      .catch((err) => {
        setSubmitSuccess(false)
      })
      .finally(() => {
        setSubmiting(false)
        setIsDialogVisible(true)
      })
  }
  function validateInput(key, value, id) {
    if (!id) return
    if (key === 'sources') {
      if (!value.length) {
        setFormValid(false)
      } else {
        setFormValid(true)
      }
      return
    }
    const inputElement = document.querySelector(id)
    const inputErrorElement = document.querySelector(id + '-error')
    const descriptionErrorElement = document.querySelector('#workDescription-length-error')
    const phoneErrorElement = document.querySelector('#phone-format-error')
    const workNameErrorElement = document.querySelector('#workName-format-error')
    const _value = key === 'workDescription' ? value.replace(/\s+/g, '') : value.trim() || ''
    const pattern = {
      phone: /^1[3456789]\d{9}$/,
      workDescription: /^.{50,500}$/,
      workName: /^.*_.*$/,
    }[key]
    if (!_value || (pattern && !pattern.test(_value))) {
      inputElement.style.borderColor = 'red'
      if (!_value) {
        inputErrorElement.style.display = 'flex'
        switch (key) {
          case 'workDescription':
            descriptionErrorElement.style.display = 'none'
            break
          case 'phone':
            phoneErrorElement.style.display = 'none'
            break
          case 'workName':
            workNameErrorElement.style.display = 'none'
            break
          default:
            break
        }
      } else {
        switch (key) {
          case 'workDescription':
            descriptionErrorElement.style.display = 'flex'
            inputErrorElement.style.display = 'none'
            break
          case 'phone':
            phoneErrorElement.style.display = 'flex'
            inputErrorElement.style.display = 'none'
            break
          case 'workName':
            workNameErrorElement.style.display = 'flex'
            inputErrorElement.style.display = 'none'
            break
          default:
            inputErrorElement.style.display = 'none'
            descriptionErrorElement.style.display = 'none'
            phoneErrorElement.style.display = 'none'
            workNameErrorElement.style.display = 'none'
            break
        }
      }
      setFormValid(false)
    } else {
      inputElement.style.borderColor = ''
      inputErrorElement.style.display = 'none'
      if (key === 'workDescription') {
        descriptionErrorElement.style.display = 'none'
      }
      if (key === 'phone') {
        phoneErrorElement.style.display = 'none'
      }
      if (key === 'workName') {
        workNameErrorElement.style.display = 'none'
      }
      setFormValid(true)
    }
  }
  function setFormDataField(key, value, id, notNecessary) {
    setFormData({
      ...formData,
      [key]: value,
    })
    if (!notNecessary) {
      validateInput(key, value, id)
    }
  }
  function getLocationSearchParam(key) {
    const query = window.location.search.substring(1)
    const entries = query.split('&')
    const map = entries.reduce((pre, cur) => {
      const [key, value] = cur.split('=')
      pre[key] = value
      return pre
    }, {})
    return map[key]
  }
  function formatFileSize(size) {
    if (size < 1024) {
      return size + 'B'
    } else if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + 'KB'
    } else if (size < 1024 * 1024 * 1024) {
      return (size / (1024 * 1024)).toFixed(2) + 'MB'
    } else {
      return (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
    }
  }
  function handleIKnow() {
    setIsDialogVisible(false)
    if (submitSuccess) {
      window.location.href = '/rc2024.html'
    }
  }

  useEffect(() => {
    if (formData.phone || formData.workDescription || formData.workName) {
      const description = formData.workDescription.replace(/\s+/g, '')
      const phonePattern = /^1[3456789]\d{9}$/
      const descriptionPattern = /^.{50,500}$/
      const workNamePattern = /^.*_.*$/
      if (
        !phonePattern.test(formData.phone) ||
        !descriptionPattern.test(description) ||
        !workNamePattern.test(formData.workName)
      ) {
        setFormValid(false)
      } else {
        setFormValid(true)
      }
    }
    let form = {}
    Object.assign(form, formData)
    delete form.zhanku
    delete form.redBook
    delete form.douyin
    const hasNull = Reflect.ownKeys(form).some((key) => {
      if (Array.isArray(formData[key])) {
        return formData[key].length === 0
      }
      return !formData[key]
    })
    const submitable =
      !hasNull &&
      uploadResult &&
      uploadResult.success &&
      uploadCoverResult &&
      uploadCoverResult.success &&
      formValid
    if (submitable) {
      const email = getLocationSearchParam('email')
      const token = getLocationSearchParam('token')
      setSubmitParams({
        ...formData,
        email,
        token,
      })
    }
    setDisabled(!submitable)
  }, [formData, formValid, uploadResult, uploadCoverResult])
  useEffect(() => {
    const email = getLocationSearchParam('email')
    const token = getLocationSearchParam('token')
    const emailHex = CryptoJS.SHA1(email).toString()
    const _token = CryptoJS.SHA1(`${email}|${emailHex.substring(16, 32)}`).toString()
    if (token !== _token) return
    const uploadSignatureURL = `https://www.renderbus.com/rc-03/oss-signature/?isCover=0&email=${email}&token=${_token}`
    if (uploader) {
      uploader.setUploadSignatureURL(uploadSignatureURL)
      uploader.refresh()
      return
    }
    const _uploader = new Plupload({
      browseButtonId: 'browse-button',
      dragElementId: 'upload-container',
      uploadAfterAdd: true,
      uploadSignatureURL,
      filters: {
        mime_types: [{ title: 'mp4 video', extensions: 'mp4' }],
        max_file_size: '500mb',
      },
    })
    _uploader.setFilesAddedCallback((up, file) => {
      setUploadResult(null)
      setUploading(true)
      const fileNameHex = CryptoJS.SHA1(email).toString()
      const suffix = file.name.includes('.') ? file.name.split('.').pop() : ''
      file.name = suffix ? `${fileNameHex}.${suffix}` : fileNameHex
      setWorkMp4Name(file.name)
      setWorkMp4Size(formatFileSize(file.size))
    })
    _uploader.setFilesUploadedCallback((up, file, info) => {
      if ([200, 203].includes(info.status)) {
        setUploadResult({
          success: true,
          fail: false,
        })
      } else {
        setUploadResult({
          success: false,
          fail: true,
        })
      }
      setUploading(false)
    })
    _uploader.setFilesUploadErrorHandler((up, err) => {
      if (err) {
        if (err.code === -601) {
          setIsWorkTypeError(true)
        } else {
          setIsWorkTypeError(false)
        }
        setUploadResult({
          success: false,
          fail: true,
        })
        setUploading(false)
      }
    })
    _uploader.setFilesUploadingHandler((up, file) => {
      const uploadBar = document.querySelector('.progress')
      uploadBar.style.width = `${file.percent}%`
    })
    _uploader.init()
    setUploader(_uploader)
  }, [uploader, uploadResult])

  useEffect(() => {
    const email = getLocationSearchParam('email')
    const token = getLocationSearchParam('token')
    const emailHex = CryptoJS.SHA1(email).toString()
    const _token = CryptoJS.SHA1(`${email}|${emailHex.substring(16, 32)}`).toString()
    if (token !== _token) return
    const uploadSignatureURL = `https://www.renderbus.com/rc-03/oss-signature/?isCover=1&email=${email}&token=${_token}`
    if (uploaderCover) {
      uploaderCover.setUploadSignatureURL(uploadSignatureURL)
      uploaderCover.refresh()
      return
    }
    const _uploader = new Plupload({
      browseButtonId: 'browse-cover-button',
      dragElementId: 'upload-cover-container',
      uploadAfterAdd: true,
      uploadSignatureURL,
      filters: {
        mime_types: 'image/jpeg,image/png',
        max_file_size: '5mb',
      },
    })
    _uploader.setFilesAddedCallback((up, file) => {
      setUploadCoverResult(null)
      setUploadingCover(true)
      const fileNameHex = CryptoJS.SHA1(email).toString()
      const suffix = file.name.includes('.') ? file.name.split('.').pop() : ''
      file.name = suffix ? `${fileNameHex}.${suffix}` : fileNameHex
      setCoverName(file.name)
      setCoverSize(formatFileSize(file.size))
    })
    _uploader.setFilesUploadedCallback((up, file, info) => {
      if ([200, 203].includes(info.status)) {
        setUploadCoverResult({
          success: true,
          fail: false,
        })
      } else {
        setUploadCoverResult({
          success: false,
          fail: true,
        })
      }
      setUploadingCover(false)
    })
    _uploader.setFilesUploadErrorHandler((up, err) => {
      if (err) {
        if (err.code === -601) {
          setIsCoverTypeError(true)
        } else {
          setIsCoverTypeError(false)
        }
        setUploadCoverResult({
          success: false,
          fail: true,
        })
        setUploadingCover(false)
      }
    })
    _uploader.setFilesUploadingHandler((up, file) => {
      const uploadBar = document.querySelector('.cover-progress')
      uploadBar.style.width = `${file.percent}%`
    })
    _uploader && _uploader.init()
    setUploaderCover(_uploader)
  }, [uploaderCover, uploadCoverResult])

  return (
    <>
      <Helmet>
        <meta http-equiv='Content-Security-Policy' content='upgrade-insecure-requests' />
        <script>
          {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
            `}
        </script>
      </Helmet>
      <SEO
        title='渲染大赛作品提交表-瑞云3D渲染动画创作大赛'
        keywords='作品提交表, 渲染大赛'
        description='3D渲染动画大赛，汇聚CG领域优秀艺术家，一起角逐大奖，赢专业评委点评，媒体曝光，为自己代言！一年一度渲染大赛现已开启正式报名，欢迎投稿参赛~'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/assets/banner.png'
        sharePostSlug='rc-submit.html'
      />
      <BannerContainer>
        <img src={BannerBg} alt=''></img>
      </BannerContainer>
      <RCFormContainer>
        <RCForm>
          <FormTitle>作品提交信息表</FormTitle>
          <FormSubTitle>这是你的专属作品提交链接不要分享给其他人哟！</FormSubTitle>
          <FormItem className='name-phone'>
            <FormItemLabel right='12'>参赛姓名:</FormItemLabel>
            <div>
              <FormInput
                value={formData.name}
                placeholder='必须和报名表一致'
                id='name'
                maxLength={5}
                onChange={(e) => setFormDataField('name', e.target.value, '#name')}
              />
              <ErrorText id='name-error'>请输入{inputFields.get('name')}</ErrorText>
            </div>
            <FormItemLabel left='100' right='12'>
              联系电话:
            </FormItemLabel>
            <div>
              <FormInput
                type='tel'
                value={formData.phone}
                right='132'
                placeholder='必须和报名表一致'
                id='phone'
                onChange={(e) => setFormDataField('phone', e.target.value, '#phone')}
              />
              <ErrorText id='phone-error'>请输入{inputFields.get('phone')}</ErrorText>
              <ErrorText id='phone-format-error'>请输入正确的联系电话</ErrorText>
            </div>
          </FormItem>
          <BottomLine />
          <FormItem top='30'>
            <FormItemLabel right='12'>制作软件:</FormItemLabel>
            <div>
              <FormInput
                value={formData.software}
                placeholder='工作流中使用到的软件'
                id='software'
                onChange={(e) => setFormDataField('software', e.target.value, '#software')}
              />
              <ErrorText id='software-error'>请输入{inputFields.get('software')}</ErrorText>
            </div>
            <FormItemLabel left='100' right='12'>
              渲染引擎:
            </FormItemLabel>
            <div>
              <FormInput
                value={formData.renderer}
                right='132'
                id='renderer'
                onChange={(e) => setFormDataField('renderer', e.target.value, '#renderer')}
              />
              <ErrorText id='renderer-error'>请输入{inputFields.get('renderer')}</ErrorText>
            </div>
          </FormItem>
          <FormItem>
            <FormItemLabel right='12'>硬件配置:</FormItemLabel>
            <div>
              <FormInput
                value={formData.hardware}
                id='hardware'
                placeholder='包括操作系统、CPU型号、显卡型号、内存'
                onChange={(e) => setFormDataField('hardware', e.target.value, '#hardware')}
              />
              <ErrorText id='hardware-error'>请输入{inputFields.get('hardware')}</ErrorText>
            </div>
            <FormItemLabel left='100' right='10'>
              制作周期:
            </FormItemLabel>
            <div>
              <FormInput
                value={formData.cycle}
                right='132'
                id='cycle'
                onChange={(e) => setFormDataField('cycle', e.target.value, '#cycle')}
              />
              <ErrorText id='cycle-error'>请输入{inputFields.get('cycle')}</ErrorText>
            </div>
          </FormItem>
          <BottomLine />
          <FormItem>
            <div>
              <FormItem top='0'>
                <WorkUploadInput
                  id='upload-cover-container'
                  uploadResult={uploadCoverResult}
                  uploading={uploadingCover}
                >
                  {!uploadingCover && !uploadCoverResult && (
                    <React.Fragment>
                      <div className='upload-icon' />
                      <p id='browse-cover-button'>上传单帧预览</p>
                      <span>（请选择代表帧作为后期展示封面）</span>
                      <span>支持jpg/png格式,不超过5M</span>
                    </React.Fragment>
                  )}
                  {(uploadingCover || (uploadCoverResult && uploadCoverResult.fail)) && (
                    <React.Fragment>
                      <img
                        className='png-icon'
                        src={uploadingCover ? PngIcon : PngWarningIcon}
                        alt=''
                      />
                      <p className='file-name'>{coverName}</p>
                      {uploadingCover ? (
                        <>
                          <p className='file-size'>{coverSize}</p>
                          <div className='cover-progress'></div>
                        </>
                      ) : (
                        <p className='fail-tip'>
                          {isCoverTypeError ? '上传失败,仅支持jpg/png格式' : '上传失败'}
                        </p>
                      )}
                      <img
                        src={uploadingCover ? CloseIcon : CloseWarningIcon}
                        alt=''
                        className='close-icon'
                        onClick={() => {
                          uploadingCover && uploaderCover.stop()
                          setUploaderCover(null)
                          setUploadingCover(false)
                          setUploadCoverResult(null)
                        }}
                      />
                    </React.Fragment>
                  )}
                  {!uploadingCover && uploadCoverResult && uploadCoverResult.success && (
                    <React.Fragment>
                      <img
                        src={`https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/${coverName}`}
                        alt=''
                        className='success-img'
                      />
                      <img
                        src={CloseIcon}
                        alt=''
                        className='close-icon'
                        onClick={() => {
                          setUploaderCover(null)
                          setUploadCoverResult(null)
                        }}
                      />
                    </React.Fragment>
                  )}
                </WorkUploadInput>
              </FormItem>
              <FormItem top='24'>
                <WorkUploadInput
                  id='upload-container'
                  uploadResult={uploadResult}
                  uploading={uploading}
                >
                  {!uploading && !uploadResult && (
                    <React.Fragment>
                      <div className='upload-icon' />
                      <p id='browse-button'>上传作品</p>
                      <span>仅支持mp4格式,不超过300M</span>
                    </React.Fragment>
                  )}
                  {(uploading || (uploadResult && uploadResult.fail)) && (
                    <React.Fragment>
                      <img className='png-icon' src={uploading ? Mp4Icon : Mp4WarningIcon} alt='' />
                      <p className='file-name'>{workMp4Name}</p>
                      {uploading ? (
                        <>
                          <p className='file-size'>{workMp4Size}</p>
                          <div className='progress'></div>
                        </>
                      ) : (
                        <p className='fail-tip'>
                          {isWorkTypeError ? '上传失败，仅支持mp4格式' : '上传失败'}
                        </p>
                      )}
                      <img
                        src={uploading ? CloseIcon : CloseWarningIcon}
                        alt=''
                        className='close-icon'
                        onClick={() => {
                          uploading && uploader.stop()
                          setUploader(null)
                          setUploading(false)
                          setUploadResult(null)
                        }}
                      />
                    </React.Fragment>
                  )}
                  {!uploading && uploadResult && uploadResult.success && (
                    <React.Fragment>
                      <img className='png-icon' src={Mp4Icon} alt='' />
                      <p className='file-name'>{workMp4Name}</p>
                      <p className='file-size'>{workMp4Size}</p>
                      <img
                        src={CloseIcon}
                        alt=''
                        className='close-icon'
                        onClick={() => {
                          setUploader(null)
                          setUploadResult(null)
                        }}
                      />
                    </React.Fragment>
                  )}
                </WorkUploadInput>
              </FormItem>
            </div>
            <div>
              <FormItem className='work-name' top='0'>
                <FormItemLabel right='8'>
                  作品名称:<span>格式要求：作品名_作者名,例：迷你世界_瑞小云</span>
                </FormItemLabel>
                <FormInput
                  value={formData.workName}
                  width='618'
                  right='132'
                  className='workname'
                  id='workName'
                  onChange={(e) => setFormDataField('workName', e.target.value, '#workName')}
                />
                <ErrorText id='workName-error'>请输入{inputFields.get('workName')}</ErrorText>
                <ErrorText id='workName-format-error'>请按照格式要求规范填写</ErrorText>
              </FormItem>
              <FormItem className='work-description' top='24'>
                <FormItemLabel right='11'>作品描述:</FormItemLabel>
                <textarea
                  value={formData.workDescription}
                  type='text'
                  className='textarea'
                  maxLength='500'
                  placeholder='50-500'
                  id='workDescription'
                  onChange={(e) =>
                    setFormDataField('workDescription', e.target.value, '#workDescription')
                  }
                />
                <ErrorText id='workDescription-error'>
                  请输入{inputFields.get('workDescription')}
                </ErrorText>
                <ErrorText id='workDescription-length-error'>请输入50-500字作品描述</ErrorText>
              </FormItem>
            </div>
          </FormItem>
          <BottomLine />
          <FormItem className='sources' top='30'>
            <FormItemLabel right='620'>
              请勾选以下使用到的软件资源（可参与特别奖评选，可多选）
            </FormItemLabel>
            <RCRadioContainer>
              {infoSourceOptions.map((source) => (
                <div key={source}>
                  <RCCheckbox
                    type='checkbox'
                    value={source}
                    id='sources'
                    checked={formData.sources.includes(source)}
                    onChange={(e) =>
                      setFormDataField(
                        'sources',
                        e.target.checked
                          ? [...formData.sources, source]
                          : formData.sources.filter((value) => value !== source),
                        '#sources',
                      )
                    }
                  />
                  <span>{source}</span>
                </div>
              ))}
            </RCRadioContainer>
          </FormItem>
          <BottomLine />
          <FormItemLabel right='732' top='30'>
            参赛作品社媒平台链接（可参与人气奖评选）
          </FormItemLabel>
          <FormItem className='bilibili' top='20'>
            <FormItemLabel right='28'>B站:</FormItemLabel>
            <div>
              <FormInput
                value={formData.bilibili}
                width='380'
                right='590'
                className='bilibili'
                id='bilibili'
                placeholder='必填'
                onChange={(e) => setFormDataField('bilibili', e.target.value, '#bilibili')}
              />
              <ErrorText id='bilibili-error'>请输入{inputFields.get('bilibili')}</ErrorText>
            </div>
          </FormItem>
          <FormItem className='zhanku' top='30'>
            <FormItemLabel right='28' notNecessary>
              站酷:
            </FormItemLabel>
            <FormInput
              value={formData.zhanku}
              width='380'
              right='590'
              className='zhanku'
              id='zhanku'
              onChange={(e) => setFormDataField('zhanku', e.target.value, '#zhanku', true)}
            />
          </FormItem>
          <FormItem className='redBook' top='30'>
            <FormItemLabel right='28' notNecessary>
              小红书:
            </FormItemLabel>
            <FormInput
              value={formData.redBook}
              width='380'
              right='590'
              className='redBook'
              id='redBook'
              onChange={(e) => setFormDataField('redBook', e.target.value, '#redBook', true)}
            />
          </FormItem>
          <FormItem className='douyin' top='20'>
            <FormItemLabel right='28' notNecessary>
              抖音:
            </FormItemLabel>
            <FormInput
              value={formData.douyin}
              width='380'
              right='590'
              className='douyin'
              id='douyin'
              onChange={(e) => setFormDataField('douyin', e.target.value, '#douyin', true)}
            />
          </FormItem>
          <BottomLine />
          <RCSubmitButton disabled={true}>{submiting ? '提交中' : '提交表单'}</RCSubmitButton>
        </RCForm>
      </RCFormContainer>
      <QrContainer>
        <p>
          赛事已截稿
          <br />
          扫码了解最新动态
        </p>
        <img src={QRImg} alt='' />
        <p>
          微信号
          <br />
          rayvision1
        </p>
      </QrContainer>
      <RCDialogContainer isActive={isDialogVisible} isSuccess={submitSuccess}>
        <div className='content'>
          <img src={QRImg} alt='' />
          <div className='text'>
            <div>
              <p>{submitSuccess ? '恭喜你作品表提交成功！' : '糟糕！作品提交失败了！'}</p>
              <span>
                {submitSuccess
                  ? '加小助手进赛事交流群，随时留意赛事信息。'
                  : '可能是因为网络原因，请您检查网络或者加大赛小助手微信反馈！'}
              </span>
            </div>
            <button onClick={handleIKnow}>我知道了</button>
          </div>
        </div>
      </RCDialogContainer>
      <RCSubmitStyle></RCSubmitStyle>
    </>
  )
}

export default RCSubmit
